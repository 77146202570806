// Dependencies
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import MiddleEllipsis from "react-middle-ellipsis";
// import Moment from 'moment';

import HeaderLogo from './assets/img/logo-text.png';

import HeroHeading from './assets/img/hero-heading.png';
import HeroImage from './assets/img/hero-image.png';

import BronzeNFT from './assets/img/bronze-nft.jpg';
import SilverNFT from './assets/img/silver-nft.jpg';
import GoldNFT from './assets/img/gold-nft.jpg';

import USP1 from './assets/img/usp1.jpg';
import USP2 from './assets/img/usp2.jpg';
import USP3 from './assets/img/usp3.jpg';

import Benefit1 from './assets/img/benefit-1.jpg';
import Benefit2 from './assets/img/benefit-2.jpg';
import Benefit3 from './assets/img/benefit-3.jpg';
import Benefit4 from './assets/img/benefit-4.jpg';
import Benefit5 from './assets/img/benefit-5.jpg';
import Benefit6 from './assets/img/benefit-6.jpg';

import About2 from './assets/img/about-2.jpg';
import WhyUs from './assets/img/why-us.jpg';

import HCToken from './assets/img/hc-token.png';
import HCTokenomics from './assets/img/hc-tokenomics.jpg';

import { isAddress } from 'ethers/lib/utils';

// hooks
import { useSignup } from './hooks/useSignup';


function App() {

  const [balError, setBalError] = useState('');
  const [invalidAmount, setInvalidAmount] = useState(true);
  const handleAmountInput = (e) => {
    const value = e.target.value;
    console.log(!isNaN(+value)); // true if its a number, false if not
		e.preventDefault();
    if (isNaN(+value)) {
      setInvalidAmount(true);
      setBalError('Input is not a number.');
    } else if (e.target.value < 1000) {
      setInvalidAmount(true);
      setBalError('Purchase quantity must be more than 1000.');
    } else if (e.target.value % 1000 != 0) {
      setInvalidAmount(true);
      setBalError('Purchase quantity must be the multiple of 1000.');
    // } else if (userData.cashWallet < e.target.value) {
    //   setInvalidAmount(true);
    //   setBalError('Insufficient fund. Please top up cash wallet.');
    } else {
      setInvalidAmount(false);
      setBalError('');
    }
    setPurchaseAmount(e.target.value);
	};

  const [activePage, setActivePage] = useState("Purchase");

  const [selectedPackage1, setSelectedPackage1] = useState(true);
  const [selectedPackage2, setSelectedPackage2] = useState(false);
  const [selectedPackage3, setSelectedPackage3] = useState(false);
  const [selectedPackage4, setSelectedPackage4] = useState(false);
  const [otherAmount, setOtherAmount] = useState(false);

  const [purchaseAmount, setPurchaseAmount] = useState(1000);


  // Set state for blockchain data
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);


  const [userData, setUserData] = useState(null);
  const fetchUserData = async () => {
    const response = await fetch('https://api.ridechain.io/user/'+blockchain.account);
    const json = await response.json();
    if (response.ok) {
        if (json.length === 1) {
          // Expect only 1 result
          setUserData(json[0]);
        } else {
          console.log("Invalid user data: More than 1 result.");
        }
    }
	};

  const getData = () => {
    if (blockchain.account !== "" && blockchain.huioneToken !== null) {
      fetchUserData();
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);


  return (
    <>

      {/* Header */}
      <section className="header">
        <div className="container">
          <div className="row">
            <div className="col-7 p-0">
              <img className="header-logo" src={HeaderLogo}></img>
            </div>
          </div>
        </div>
      </section>

      {/* Hero Banner */}
      <section className="hero-banner py-5">
        <div className="container">

          <div className="row">
            <div className="col-12 p-0 text-center">
              <img className="hero-heading px-3" src={HeroHeading}></img>
              <h4 className="text-white mt-3">全球獨家種財基秘法</h4>
              <p className="text-white">凝聚善业功德，开拓財富之门</p>
              <img className="hero-image mt-5 px-3" src={HeroImage}></img>
            </div>
          </div>

          <div className="row" style={{marginBottom:"100px"}}>
            <div className="col-12 p-0 mt-5 text-center">
              <h2 className="text-white mt-3">選擇種財基配套</h2>
            </div>
            <div className="col-md-4 mt-3 text-center">
              <img className="nft-image" src={GoldNFT}></img>
            </div>
            <div className="col-md-4 mt-3 text-center">
              <img className="nft-image" src={SilverNFT}></img>
            </div>
            <div className="col-md-4 mt-3 text-center">
              <img className="nft-image" src={BronzeNFT}></img>
            </div>
          </div>

        </div>
      </section>


      {/* About */}
      <section className="about-us">
        <div className="container">
          <div className="row">
            <div className="col-12 p-0 text-center">
              <h3 className="sand mt-3">種財基</h3>
              <p className="text-white">种財基是由陰善獨创的改运秘法，推崇以善业功德种福，将善行转换為財富，以达到改善人們的財运和命运的目的。</p>
            </div>
          </div>
        </div>
      </section>


      {/* USP */}
      <section className="usp">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 p-0 text-center sand-bg">
              <img className="w-100" src={USP1}></img>
              <div className="pt-3 pb-5 px-5">
                <h5 className="black mt-3 mb-3">種財基文化</h5>
                <p className="black" style={{fontSize:"14px"}}>財基是人的財库根基，命盘先天不足，可用后天努力补足。而种財基是利用大自然力量，调整个人运势的方法。</p>
              </div>
            </div>
            <div className="col-md-4 p-0 text-center dirt-bg">
              <img className="w-100" src={USP2}></img>
              <div className="pt-3 pb-5 px-5">
                <h5 className="black mt-3 mb-3">種財基好处</h5>
                <p className="black" style={{fontSize:"14px"}}>財基是人的財库根基，命盘先天不足，可用后天努力补足。而种財基是利用大自然力量，调整个人运势的方法。</p>
              </div>
            </div>
            <div className="col-md-4 p-0 text-center dirt-bro-bg">
              <img className="w-100" src={USP3}></img>
              <div className="pt-3 pb-5 px-5">
                <h5 className="black mt-3 mb-3">如何種財基</h5>
                <p className="black" style={{fontSize:"14px"}}>財基是人的財库根基，命盘先天不足，可用后天努力补足。而种財基是利用大自然力量，调整个人运势的方法。</p>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* Why You Need Us */}
      <section className="why-you-need">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <h3 className="sand" style={{marginTop:"50px"}}>為何您需要種財基?</h3>
              <p className="black my-5">我們的种財基服务以客户需求為出发点，精心设计和布置每一个財基风水財位，以确保最大化发挥效果。 我們提供专业且全面的服务，协助客户实现財运、事业、健康和幸福的全面提升。</p>
            </div>
            <div className="col-lg-8 text-center">
              <div className="row">

                <div className="col-sm-6 mt-3">
                  <div className="row">
                    <div className="col-6 px-2">
                      <img className="w-100" src={Benefit1}></img>
                    </div>
                    <div className="col-6 px-2">
                      <p className="black mt-3" style={{fontSize:"18px"}}><strong>提升財運<br></br>掌握錢財</strong></p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 mt-3">
                  <div className="row">
                    <div className="col-6 px-2">
                      <img className="w-100" src={Benefit2}></img>
                    </div>
                    <div className="col-6 px-2">
                      <p className="black mt-3" style={{fontSize:"18px"}}><strong>補財添福<br></br>福澤子孫</strong></p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 mt-3">
                  <div className="row">
                    <div className="col-6 px-2">
                      <img className="w-100" src={Benefit3}></img>
                    </div>
                    <div className="col-6 px-2">
                      <p className="black mt-3" style={{fontSize:"18px"}}><strong>減少破財<br></br>避免意外</strong></p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 mt-3">
                  <div className="row">
                    <div className="col-6 px-2">
                      <img className="w-100" src={Benefit4}></img>
                    </div>
                    <div className="col-6 px-2">
                      <p className="black mt-3" style={{fontSize:"18px"}}><strong>突破瓶頸<br></br>投資順利</strong></p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 mt-3">
                  <div className="row">
                    <div className="col-6 px-2">
                      <img className="w-100" src={Benefit5}></img>
                    </div>
                    <div className="col-6 px-2">
                      <p className="black mt-3" style={{fontSize:"18px"}}><strong>餘錢不斷<br></br>衣食無缺</strong></p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 mt-3">
                  <div className="row">
                    <div className="col-6 px-2">
                      <img className="w-100" src={Benefit6}></img>
                    </div>
                    <div className="col-6 px-2">
                      <p className="black mt-3" style={{fontSize:"18px"}}><strong>工作順利<br></br>生意興隆</strong></p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>


      {/* About Our Temple */}
      <section className="about-us-2">
        <div className="container">
          <div className="row">
            <div className="col-md-6 text-center">
              <img className="about-us-2-img" src={About2}></img>
            </div>
            <div className="col-md-6">
              <h2 className="sand add-margin-top">關于陰善七鑫財基</h2>
              <p className="text-white">陰善七鑫財基的前身為陰善殿的“谢范将军文化世界总会”，成立多年以来致力于传承谢范将军精神文化。</p>
              <p className="text-white">陰善七鑫財基的创立源自于发扬陰財神之“以商养善”的精神，旨在以博大精深的生基文化為基石，结合现代化“行善聚富”的理念，為善主提供种財基服务。</p>
              <p className="text-white">我們深信，通过培养善念和行善积德，每个人都能够增长自己的福报，并转化為財富。</p>
            </div>
          </div>
        </div>
      </section>


      {/* Why Us */}
      <section className="why-us">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="sand add-margin-top">為什么選擇我們?</h2>
              <p className="text-white mb-5">我們的种財基服务以客户需求為出发点，精心设计和布置每一个財基风水財位，以确保最大化发挥效果。 我們提供专业且全面的服务，协助客户实现財运、事业、健康和幸福的全面提升。</p>
            </div>
            <div className="col-md-6 text-center">
              <img className="about-us-2-img" src={WhyUs}></img>
            </div>
          </div>
        </div>
      </section>


      {/* Gallery */}
      <section className="gallery">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="sand">種財基环境与活动回憶錄</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-lg-3 mt-3 px-2 text-center">
              <img className="gallery-image" src={USP1}></img>
            </div>
            <div className="col-6 col-lg-3 mt-3 px-2 text-center">
              <img className="gallery-image" src={USP2}></img>
            </div>
            <div className="col-6 col-lg-3 mt-3 px-2 text-center">
              <img className="gallery-image" src={USP3}></img>
            </div>
            <div className="col-6 col-lg-3 mt-3 px-2 text-center">
              <img className="gallery-image" src={USP1}></img>
            </div>
            <div className="col-6 col-lg-3 mt-3 px-2 text-center">
              <img className="gallery-image" src={USP2}></img>
            </div>
            <div className="col-6 col-lg-3 mt-3 px-2 text-center">
              <img className="gallery-image" src={USP3}></img>
            </div>
            <div className="col-6 col-lg-3 mt-3 px-2 text-center">
              <img className="gallery-image" src={USP1}></img>
            </div>
            <div className="col-6 col-lg-3 mt-3 px-2 text-center">
              <img className="gallery-image" src={USP2}></img>
            </div>
          </div>
        </div>
      </section>

      <Router>
        <Routes>
        </Routes>
      </Router>

    </>
  );
}

export default App;